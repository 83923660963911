import { HeapAudioBuffer } from './HeapAudioBuffer';
import { Module } from './Module';

type ProcessCallback = (
  processor: any,
  inputPtr: number,
  outputPtr: number,
  parameters: { [name: string]: Float32Array },
  channelCount: number,
) => void;

export class WASMWorkletProcessor extends AudioWorkletProcessor {
  _heapInputBuffer: HeapAudioBuffer;
  _heapOutputBuffer: HeapAudioBuffer;
  _processor: any;
  _process: ProcessCallback;

  constructor(Module: Module, process: ProcessCallback) {
    super();

    // Allocate the buffer for the heap access. Start with stereo, but it can
    // be expanded up to 32 channels.
    this._heapInputBuffer = new HeapAudioBuffer(Module, 128, 2);
    this._heapOutputBuffer = new HeapAudioBuffer(Module, 128, 2);
    this._processor = new Module.Processor();
    this._process = process;
  }

  process(
    inputs: Float32Array[][],
    outputs: Float32Array[][],
    parameters: { [key: string]: Float32Array },
  ): boolean {
    // Use the 1st input and output only to make the example simpler. |input|
    // and |output| here have the similar structure with the AudioBuffer
    // interface. (i.e. An array of Float32Array)
    let input = inputs[0];
    let output = outputs[0];

    // For this given render quantum, the channel count of the node is fixed
    // and identical for the input and the output.
    let channelCount = input.length;

    // Prepare HeapAudioBuffer for the channel count change in the current
    // render quantum.
    this._heapInputBuffer.adaptChannel(channelCount);
    this._heapOutputBuffer.adaptChannel(channelCount);

    // Copy-in, process and copy-out.
    for (let channel = 0; channel < channelCount; ++channel) {
      if (input[channel].length === 0) {
        // TODO: once dynamic input/channel counts are implemented this won't be
        // necesssary.  In the meantime, we need to zero the inputs so that when
        // disconnected they don't loop the last buffer they saw.
        this._heapInputBuffer.getChannelData(channel).fill(0);
      } else {
        this._heapInputBuffer.getChannelData(channel).set(input[channel]);
      }
    }
    this._process(
      this._processor,
      this._heapInputBuffer.getHeapAddress(),
      this._heapOutputBuffer.getHeapAddress(),
      parameters,
      channelCount,
    );
    for (let channel = 0; channel < channelCount; ++channel) {
      output[channel].set(this._heapOutputBuffer.getChannelData(channel));
    }

    return true;
  }
}
