import { Module } from './Module';

// Basic byte unit of WASM heap.
const BYTES_PER_UNIT = Uint16Array.BYTES_PER_ELEMENT;

// Byte per audio sample. (32 bit float)
const BYTES_PER_SAMPLE = Float32Array.BYTES_PER_ELEMENT;

export class HeapAudioBuffer {
  _module: Module;
  _length: number;
  _channelCount: number;
  _isInitialized: boolean;
  _dataPtr: number;
  _channelData: Float32Array[];

  constructor(wasmModule: Module, length: number, channelCount: number) {
    this._module = wasmModule;
    this._length = length;
    this._channelCount = channelCount;

    const channelByteSize = this._length * BYTES_PER_SAMPLE;
    const dataByteSize = this._channelCount * channelByteSize;
    this._dataPtr = this._module._malloc(dataByteSize);
    this._channelData = [];
    for (let i = 0; i < this._channelCount; ++i) {
      let startByteOffset = this._dataPtr + i * channelByteSize;
      let endByteOffset = startByteOffset + channelByteSize;
      // Get the actual array index by dividing the byte offset by 2 bytes.
      this._channelData[i] = this._module.HEAPF32.subarray(
        startByteOffset >> BYTES_PER_UNIT,
        endByteOffset >> BYTES_PER_UNIT,
      );
    }

    this._isInitialized = true;
  }

  // Adapt the current channel count to the new input buffer.
  adaptChannel(newChannelCount: number): void {
    this._channelCount = newChannelCount;
  }

  // Returns a Float32Array object for a given channel index. If the channel
  // index is undefined, it returns the reference to the entire array of channel
  // data.
  getChannelData(channelIndex: number): Float32Array {
    return this._channelData[channelIndex];
  }

  // Returns the base address of the allocated memory space in the WASM heap.
  getHeapAddress(): number {
    return this._dataPtr;
  }

  // Frees the allocated memory space in the WASM heap.
  free(): void {
    this._isInitialized = false;
    this._module._free(this._dataPtr);
    this._channelData = [];
  }
}
