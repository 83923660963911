import { WASMWorkletProcessor } from '../../audioUtils';
// @ts-ignore
import Module from './Processor.wasmmodule.js';

registerProcessor(
  'PitchShifterProcessor',
  class PitchShifterProcessor extends WASMWorkletProcessor {
    static get parameterDescriptors() {
      return [
        {
          name: 'detune',
          defaultValue: 0,
          minValue: -153600,
          maxValue: 153600,
          automationRate: 'k-rate',
        },
      ];
    }

    constructor() {
      super(
        Module,
        (processor, inputPointer, outputPointer, { detune: [detune] }) => {
          processor.process(inputPointer, outputPointer, detune);
        },
      );
    }
  },
);
